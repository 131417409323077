/* Position and sizing of burger button */
.bm-burger-button {
  --icon-size: calc(var(--header-height) * 0.4);

  position: relative;
  width: var(--icon-size);
  height: var(--icon-size);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  top: 0.9rem !important;
  left: 0.9rem !important;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  transition: all 0.1s ease 0s !important;
  overflow: auto;
  background: #111;
}

/* General sidebar styles */
.bm-menu {
  height: initial !important;
  padding: 0 1rem;
  padding-bottom: 2rem;
  overflow: initial !important;
}

/* Styling of overlay */
.bm-overlay {
  background: rgb(0 0 0 / 30%);
  top: 0;
  left: 0;
}
