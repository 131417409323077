@import-normalize;

@import url('react-responsive-carousel/lib/styles/carousel.min.css');
@import url('swiper/css');
@import url('swiper/css/free-mode');
@import url('swiper/css/navigation');
@import url('./Swiper.css');
@import url('./Slider.css');
@import url('./BurgerMenu.css');
@import url('./MarkdownContent.css');
@import url('./ReactSimpleImageViewer.css');
@import url('./CookieConsent.css');

:root {
  --body-bg-color: #fff;
  --bg-color: #fff;
  --bg-color-alt: #0a0b0b;
  --bg-card: #252021;
  --bg-card-title: #181515;
  --text-color: #000;
  --text-color-alt: #fff;
  --text-color-alt-attenuate: #888;
  --separator-color: #eee;
  --color-primary: #dbb252;
  --rgb-color-primary: 219 178 82;
  --color-primary-attenuate: #9a6d04;

  /* --color-primary-attenuate: #ad7a01; */
  --padding-default: 1rem;
  --horizontal-spacing-default: 0.7rem;
  --tablet-max-width: 95vw;
  --desktop-max-width: 1200px;
  --header-height: 50px;
  --icon-size: 24px;
  --transition-default: all 100ms linear;
  --mobile-breakpoint: 768px;
  --padding-content-text: calc(var(--horizontal-spacing-default) * 1.2);
  --chronology-size: 6rem;
  --identity-container-width: 20rem;
  --swiper-navigation-color: #fff;

  scroll-padding-top: calc(var(--header-height) + var(--horizontal-spacing-default));
  scroll-behavior: smooth;
}

@media (width >= 1050px) {
  :root {
    --identity-container-width: 25rem;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background: var(--bg-color-alt);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
  outline: none;
}

* {
  /* Remove blue on click overlay */
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

.ml-1 {
  margin-left: var(--horizontal-spacing-default);
}

.mr-1 {
  margin-right: var(--horizontal-spacing-default);
}

.mt-1 {
  margin-top: var(--horizontal-spacing-default);
}

.mt-4 {
  margin-top: calc(var(--horizontal-spacing-default) * 4);
}

.ratio-2-3 {
  aspect-ratio: 2/3;
}

.ratio-4-3 {
  aspect-ratio: 4/3;
}

.ratio-1-1 {
  aspect-ratio: 1;
}
