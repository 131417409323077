.CookieConsent {
  background: rgba(10 11 11 / 95%);
  color: var(--text-color-alt-attenuate);
  font-size: 0.8rem;
  padding: 0.1rem;
  bottom: 0;
  flex-flow: row;
  align-items: center;
  display: flex;
  justify-content: space-between;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 999;
  backdrop-filter: blur(5px);
}

.CookieConsent > div {
  margin: 0.5rem;
}

#rcc-confirm-button {
  background: var(--color-primary);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  color: rgb(10 11 11);
  cursor: pointer;
  flex: 0 0 auto;
  padding: 5px 10px;
}
