.swiper-slide {
  width: initial;
  margin-right: var(--horizontal-spacing-default);
}

.swiper-button-next,
.swiper-button-prev {
  text-shadow:
    0 0 10px black,
    0 0 5px black;
  transition: var(--transition-default);
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  color: var(--color-primary);
  transition: var(--transition-default);
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}
