.Content__Picture {
  margin-top: calc(var(--horizontal-spacing-default) * 4);
}

.Content__Picture p {
  margin: 0;
  text-align: center;
}

.Content__Picture--center {
  text-align: center;
}

.Content__Picture--left,
.Content__Picture--right {
  max-width: 250px;
}

@media (width >= 768px) {
  .Content__Picture--left,
  .Content__Picture--right {
    --image-width: calc(var(--identity-container-width) * 0.7);

    width: var(--image-width);
    position: absolute;
    right: calc(calc(var(--identity-container-width) - var(--image-width)) / 2);
  }
}

.Content__Picture--left {
  float: left;
  margin-right: var(--horizontal-spacing-default);
}

.Content__Picture--right {
  float: right;
  margin-left: var(--horizontal-spacing-default);
}

@media (width <= 768px) {
  .Content__Picture,
  .Content__Picture--left,
  .Content__Picture--right {
    width: 100vw !important;
    max-width: initial !important;
    float: none !important;
    margin: calc(var(--horizontal-spacing-default) * 3) calc(-1 * var(--padding-content-text))
      calc(var(--horizontal-spacing-default) * 1) calc(-1 * var(--padding-content-text));
  }
}

.Content__Picture__Description {
  color: var(--text-color-alt-attenuate);
  font-size: 0.8rem;
}
