/* stylelint-disable selector-id-pattern */
#ReactSimpleImageViewer {
  max-width: 100%;
  z-index: 10;
  cursor: pointer;
}

#ReactSimpleImageViewer img {
  cursor: default;
}
